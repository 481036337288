@import "./scss/index.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 20px;
  text-decoration: none;
}

li {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 16px;
  font-size: 14px;
  line-height: 1.5;
  color: #586069;
  border-bottom: 2px solid transparent;
  text-decoration: none;
}

li:hover {
  cursor: pointer;
  color: #24292e;
  border-bottom-color: #d1d5da;
  transition: 0.2s ease;
  text-decoration: none;
  /* border-bottom: 2.5px solid #d1d5da; */
}

.active {
  font-weight: 600;
  color: #24292e;
  border-bottom-color: #e36209;
  text-decoration: none;
}

.active:hover {
  font-weight: 600;
  color: #24292e;
  border-bottom-color: #e36209;
  text-decoration: none;
}

h1 {
  text-align: center;
  margin: 90px;
}

// img.img-responsive{
//   height:800px;
//   width: 1100px;
//   margin-left:400px;
// }

h2 {
  margin-left: 0px;
}

.Toastify {
  .Toastify__toast-container--top-center {
    bottom: 0;
    left: 10;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;

    .Toastify__toast--success {
      background-color: $primary-color-cyan;

      .Toastify__close-button {
        align-self: center;
      }
    }
    .Toastify__toast--warning {
      background-color: $primary-color-orange;

      .Toastify__close-button {
        align-self: center;
      }
    }
  }
}
