@import "../../scss/index.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #101010;
}

a.ui.button.btn-b12-unit1 {
  position: relative;
  margin-top: 2%;
  width: 50%;
  max-width: 200px;
  background-color: #39c0c3;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50px;
  &:hover {
    background-color: $primary-color-cyan-hover;
  }
}

a.ui.button.btn-b12-unit2 {
  position: relative;
  margin-top: 2%;
  //margin-top:100px;
  top: -150px;
  width: 50%;
  max-width: 200px;
  background-color: #39c0c3;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50px;
  &:hover {
    background-color: $primary-color-cyan-hover;
  }
}

a.ui.button.btn-b12-unit3 {
  position: relative;
  margin-top: 2%;
  //margin-top:100px;
  top: -300px;
  width: 50%;
  max-width: 200px;
  background-color: #39c0c3;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50px;
  &:hover {
    background-color: $primary-color-cyan-hover;
  }
}

a.ui.button.btn-b12-unit4 {
  position: relative;
  margin-top: 2%;
  //margin-top:100px;
  top: -450px;
  width: 50%;
  max-width: 200px;
  background-color: #39c0c3;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50px;
  &:hover {
    background-color: $primary-color-cyan-hover;
  }
}

a.ui.button.btn-b12-unit5 {
  position: relative;
  margin-top: 2%;
  //margin-top:100px;
  top: -600px;
  width: 50%;
  max-width: 200px;
  background-color: #39c0c3;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50px;
  &:hover {
    background-color: $primary-color-cyan-hover;
  }
}

a.ui.button.btn-b12-unit6 {
  position: relative;
  margin-top: 2%;
  //margin-top:100px;
  top: -750px;
  width: 50%;
  max-width: 200px;
  background-color: #39c0c3;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50px;
  &:hover {
    background-color: $primary-color-cyan-hover;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  // border: 3px solid green;
}

h2.a22 {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

h1.a22 {
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
